import "./styles/App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Homard from "./pages/homard/Homard";
import Wall from "./pages/homard/wall/Wall";
import Home from "./pages/Home";
import Live from "./pages/Live";
// import the SDK provided styles
import "@stream-io/video-react-sdk/dist/css/styles.css";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/homard/wall" element={<Wall />} />
                <Route path="/homard" element={<Homard />} />
                <Route path="/live" element={<Live />} />
                {/* <Redirect to="/" /> */}
            </Routes>
        </Router>
    );
}

export default App;
