import React from "react";
import Image from "material-ui-image";
import { makeStyles } from "@material-ui/core/styles";
import homardPic from "../../assets/homard.jpg";

function Homard() {
    const classes = useStyles();
    return (
        <Image
            src={homardPic}
            className={classes.homardImg}
            cover={true}
            aspectRatio={4 / 3}></Image>
    );
}

const useStyles = makeStyles(() => ({
    homardImg: {},
}));

export default Homard;
