import React, { FC, useState } from "react";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Image from "material-ui-image";
import {
    StreamTheme,
    StreamVideo,
    StreamVideoClient,
    LivestreamPlayer,
    User,
} from "@stream-io/video-react-sdk";
import homardPic from "assets/live.png";

const apiKey = "p5wm975fjn4m";
const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJAc3RyZWFtLWlvL2Rhc2hib2FyZCIsImlhdCI6MTcyMTQ1ODgyMiwiZXhwIjoxNzIxNTQ1MjIyLCJ1c2VyX2lkIjoiIWFub24iLCJyb2xlIjoidmlld2VyIiwiY2FsbF9jaWRzIjpbImxpdmVzdHJlYW06bGl2ZXN0cmVhbV85N2M5MTA0MS0xZjVhLTQ5ZWEtOTBiZS1kZDIxZWY1ZGFhMjAiXX0.wv9qxTe32Mrd5STpYx5uZTFUVqKe09cr__kYQkX4aRo";
const callId = "livestream_97c91041-1f5a-49ea-90be-dd21ef5daa20";

const user: User = { type: "anonymous" };
const client = new StreamVideoClient({ apiKey, user, token });

const Live: FC = () => {
    const [play, setPlay] = useState(false);

    if (!play) {
        return (
            <Image
                src={homardPic}
                // className={classes.homardImg}
                // cover={true}
                onClick={() => setPlay(true)}
                aspectRatio={4 / 3}
            />
        );
        // return <PlayArrowIcon onClick={() => setPlay(true)} />;
    }
    return (
        <StreamTheme style={{ fontFamily: "sans-serif", color: "white" }}>
            <StreamVideo client={client}>
                <LivestreamPlayer callType="livestream" callId={callId} />
            </StreamVideo>
        </StreamTheme>
    );
};

export default Live;
