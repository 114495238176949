import React, { useEffect } from "react";

function Home() {
    useEffect(() => {
        window.location.assign("https://soundcloud.com/rambalkoshe");
    }, []);

    return <div></div>;
}

export default Home;
