import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import InnerImageZoom from "react-inner-image-zoom";
import wallPic from "../../../assets/wall.png";

function Wall() {
    const classes = useStyles();
    return (
        <div className={classes.imgWrapper}>
            <InnerImageZoom src={wallPic} zoomType="hover" hideHint />
        </div>
    );
}

const useStyles = makeStyles(() => ({
    imgWrapper: {
        display: "flex",
        alignItems: "center",
        height: "inherit",
    },
}));

export default Wall;
